import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Nav from "./components/Nav";
import RouterSwitch from "./components/RouterSwitch";

import './styles/_reset.scss';
import './styles/_grid.scss';
import './styles/_utilities.scss';

function App() {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<object>();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <Router>
      <RouterSwitch />
      <Nav />
    </Router>
  ) : (
    <AmplifyAuthenticator usernameAlias="email" />
  );
}

export default App;
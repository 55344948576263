/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTripInput = {
  id?: string | null,
  title: string,
  startDate?: string | null,
  endDate?: string | null,
  invitees?: Array< string | null > | null,
  tripItineraryId?: string | null,
  tripPackingListId?: string | null,
};

export type ModelTripConditionInput = {
  title?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelTripConditionInput | null > | null,
  or?: Array< ModelTripConditionInput | null > | null,
  not?: ModelTripConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type UpdateTripInput = {
  id: string,
  title?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  invitees?: Array< string | null > | null,
  tripItineraryId?: string | null,
  tripPackingListId?: string | null,
};

export type DeleteTripInput = {
  id?: string | null,
};

export type CreateItineraryInput = {
  id?: string | null,
  invitees?: Array< string | null > | null,
};

export type ModelItineraryConditionInput = {
  and?: Array< ModelItineraryConditionInput | null > | null,
  or?: Array< ModelItineraryConditionInput | null > | null,
  not?: ModelItineraryConditionInput | null,
};

export enum ActivityType {
  beach = "beach",
  mountain = "mountain",
  desert = "desert",
  snow = "snow",
}


export type UpdateItineraryInput = {
  id: string,
  invitees?: Array< string | null > | null,
};

export type DeleteItineraryInput = {
  id?: string | null,
};

export type CreateActivityInput = {
  id?: string | null,
  itineraryID: string,
  name: string,
  type?: ActivityType | null,
  cost?: number | null,
  startTime?: string | null,
  endTime?: string | null,
  notes?: Array< string | null > | null,
  invitees?: Array< string | null > | null,
};

export type ModelActivityConditionInput = {
  itineraryID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelActivityTypeInput | null,
  cost?: ModelFloatInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelActivityConditionInput | null > | null,
  or?: Array< ModelActivityConditionInput | null > | null,
  not?: ModelActivityConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelActivityTypeInput = {
  eq?: ActivityType | null,
  ne?: ActivityType | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateActivityInput = {
  id: string,
  itineraryID?: string | null,
  name?: string | null,
  type?: ActivityType | null,
  cost?: number | null,
  startTime?: string | null,
  endTime?: string | null,
  notes?: Array< string | null > | null,
  invitees?: Array< string | null > | null,
};

export type DeleteActivityInput = {
  id?: string | null,
};

export type CreatePackingListInput = {
  id?: string | null,
  invitees?: Array< string | null > | null,
};

export type ModelPackingListConditionInput = {
  and?: Array< ModelPackingListConditionInput | null > | null,
  or?: Array< ModelPackingListConditionInput | null > | null,
  not?: ModelPackingListConditionInput | null,
};

export type UpdatePackingListInput = {
  id: string,
  invitees?: Array< string | null > | null,
};

export type DeletePackingListInput = {
  id?: string | null,
};

export type CreatePackingItemInput = {
  id?: string | null,
  packingListID: string,
  name?: string | null,
  completed?: boolean | null,
  invitees?: Array< string | null > | null,
};

export type ModelPackingItemConditionInput = {
  packingListID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  completed?: ModelBooleanInput | null,
  and?: Array< ModelPackingItemConditionInput | null > | null,
  or?: Array< ModelPackingItemConditionInput | null > | null,
  not?: ModelPackingItemConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdatePackingItemInput = {
  id: string,
  packingListID?: string | null,
  name?: string | null,
  completed?: boolean | null,
  invitees?: Array< string | null > | null,
};

export type DeletePackingItemInput = {
  id?: string | null,
};

export type ModelTripFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  invitees?: ModelStringInput | null,
  and?: Array< ModelTripFilterInput | null > | null,
  or?: Array< ModelTripFilterInput | null > | null,
  not?: ModelTripFilterInput | null,
};

export type ModelItineraryFilterInput = {
  id?: ModelIDInput | null,
  invitees?: ModelStringInput | null,
  and?: Array< ModelItineraryFilterInput | null > | null,
  or?: Array< ModelItineraryFilterInput | null > | null,
  not?: ModelItineraryFilterInput | null,
};

export type ModelActivityFilterInput = {
  id?: ModelIDInput | null,
  itineraryID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelActivityTypeInput | null,
  cost?: ModelFloatInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  invitees?: ModelStringInput | null,
  and?: Array< ModelActivityFilterInput | null > | null,
  or?: Array< ModelActivityFilterInput | null > | null,
  not?: ModelActivityFilterInput | null,
};

export type ModelPackingListFilterInput = {
  id?: ModelIDInput | null,
  invitees?: ModelStringInput | null,
  and?: Array< ModelPackingListFilterInput | null > | null,
  or?: Array< ModelPackingListFilterInput | null > | null,
  not?: ModelPackingListFilterInput | null,
};

export type ModelPackingItemFilterInput = {
  id?: ModelIDInput | null,
  packingListID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  completed?: ModelBooleanInput | null,
  invitees?: ModelStringInput | null,
  and?: Array< ModelPackingItemFilterInput | null > | null,
  or?: Array< ModelPackingItemFilterInput | null > | null,
  not?: ModelPackingItemFilterInput | null,
};

export type CreateTripMutationVariables = {
  input: CreateTripInput,
  condition?: ModelTripConditionInput | null,
};

export type CreateTripMutation = {
  createTrip:  {
    __typename: "Trip",
    id: string,
    title: string,
    itinerary:  {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    packingList:  {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    startDate: string | null,
    endDate: string | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateTripMutationVariables = {
  input: UpdateTripInput,
  condition?: ModelTripConditionInput | null,
};

export type UpdateTripMutation = {
  updateTrip:  {
    __typename: "Trip",
    id: string,
    title: string,
    itinerary:  {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    packingList:  {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    startDate: string | null,
    endDate: string | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeleteTripMutationVariables = {
  input: DeleteTripInput,
  condition?: ModelTripConditionInput | null,
};

export type DeleteTripMutation = {
  deleteTrip:  {
    __typename: "Trip",
    id: string,
    title: string,
    itinerary:  {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    packingList:  {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    startDate: string | null,
    endDate: string | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreateItineraryMutationVariables = {
  input: CreateItineraryInput,
  condition?: ModelItineraryConditionInput | null,
};

export type CreateItineraryMutation = {
  createItinerary:  {
    __typename: "Itinerary",
    id: string,
    activities:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        itineraryID: string,
        name: string,
        type: ActivityType | null,
        cost: number | null,
        startTime: string | null,
        endTime: string | null,
        notes: Array< string | null > | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateItineraryMutationVariables = {
  input: UpdateItineraryInput,
  condition?: ModelItineraryConditionInput | null,
};

export type UpdateItineraryMutation = {
  updateItinerary:  {
    __typename: "Itinerary",
    id: string,
    activities:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        itineraryID: string,
        name: string,
        type: ActivityType | null,
        cost: number | null,
        startTime: string | null,
        endTime: string | null,
        notes: Array< string | null > | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeleteItineraryMutationVariables = {
  input: DeleteItineraryInput,
  condition?: ModelItineraryConditionInput | null,
};

export type DeleteItineraryMutation = {
  deleteItinerary:  {
    __typename: "Itinerary",
    id: string,
    activities:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        itineraryID: string,
        name: string,
        type: ActivityType | null,
        cost: number | null,
        startTime: string | null,
        endTime: string | null,
        notes: Array< string | null > | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreateActivityMutationVariables = {
  input: CreateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type CreateActivityMutation = {
  createActivity:  {
    __typename: "Activity",
    id: string,
    itineraryID: string,
    name: string,
    type: ActivityType | null,
    cost: number | null,
    startTime: string | null,
    endTime: string | null,
    notes: Array< string | null > | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateActivityMutationVariables = {
  input: UpdateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type UpdateActivityMutation = {
  updateActivity:  {
    __typename: "Activity",
    id: string,
    itineraryID: string,
    name: string,
    type: ActivityType | null,
    cost: number | null,
    startTime: string | null,
    endTime: string | null,
    notes: Array< string | null > | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeleteActivityMutationVariables = {
  input: DeleteActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type DeleteActivityMutation = {
  deleteActivity:  {
    __typename: "Activity",
    id: string,
    itineraryID: string,
    name: string,
    type: ActivityType | null,
    cost: number | null,
    startTime: string | null,
    endTime: string | null,
    notes: Array< string | null > | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreatePackingListMutationVariables = {
  input: CreatePackingListInput,
  condition?: ModelPackingListConditionInput | null,
};

export type CreatePackingListMutation = {
  createPackingList:  {
    __typename: "PackingList",
    id: string,
    items:  {
      __typename: "ModelPackingItemConnection",
      items:  Array< {
        __typename: "PackingItem",
        id: string,
        packingListID: string,
        name: string | null,
        completed: boolean | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdatePackingListMutationVariables = {
  input: UpdatePackingListInput,
  condition?: ModelPackingListConditionInput | null,
};

export type UpdatePackingListMutation = {
  updatePackingList:  {
    __typename: "PackingList",
    id: string,
    items:  {
      __typename: "ModelPackingItemConnection",
      items:  Array< {
        __typename: "PackingItem",
        id: string,
        packingListID: string,
        name: string | null,
        completed: boolean | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeletePackingListMutationVariables = {
  input: DeletePackingListInput,
  condition?: ModelPackingListConditionInput | null,
};

export type DeletePackingListMutation = {
  deletePackingList:  {
    __typename: "PackingList",
    id: string,
    items:  {
      __typename: "ModelPackingItemConnection",
      items:  Array< {
        __typename: "PackingItem",
        id: string,
        packingListID: string,
        name: string | null,
        completed: boolean | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreatePackingItemMutationVariables = {
  input: CreatePackingItemInput,
  condition?: ModelPackingItemConditionInput | null,
};

export type CreatePackingItemMutation = {
  createPackingItem:  {
    __typename: "PackingItem",
    id: string,
    packingListID: string,
    name: string | null,
    completed: boolean | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdatePackingItemMutationVariables = {
  input: UpdatePackingItemInput,
  condition?: ModelPackingItemConditionInput | null,
};

export type UpdatePackingItemMutation = {
  updatePackingItem:  {
    __typename: "PackingItem",
    id: string,
    packingListID: string,
    name: string | null,
    completed: boolean | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeletePackingItemMutationVariables = {
  input: DeletePackingItemInput,
  condition?: ModelPackingItemConditionInput | null,
};

export type DeletePackingItemMutation = {
  deletePackingItem:  {
    __typename: "PackingItem",
    id: string,
    packingListID: string,
    name: string | null,
    completed: boolean | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type GetTripQueryVariables = {
  id: string,
};

export type GetTripQuery = {
  getTrip:  {
    __typename: "Trip",
    id: string,
    title: string,
    itinerary:  {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    packingList:  {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    startDate: string | null,
    endDate: string | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListTripsQueryVariables = {
  filter?: ModelTripFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTripsQuery = {
  listTrips:  {
    __typename: "ModelTripConnection",
    items:  Array< {
      __typename: "Trip",
      id: string,
      title: string,
      itinerary:  {
        __typename: "Itinerary",
        id: string,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      packingList:  {
        __typename: "PackingList",
        id: string,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      startDate: string | null,
      endDate: string | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetItineraryQueryVariables = {
  id: string,
};

export type GetItineraryQuery = {
  getItinerary:  {
    __typename: "Itinerary",
    id: string,
    activities:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        itineraryID: string,
        name: string,
        type: ActivityType | null,
        cost: number | null,
        startTime: string | null,
        endTime: string | null,
        notes: Array< string | null > | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListItinerarysQueryVariables = {
  filter?: ModelItineraryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItinerarysQuery = {
  listItinerarys:  {
    __typename: "ModelItineraryConnection",
    items:  Array< {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetActivityQueryVariables = {
  id: string,
};

export type GetActivityQuery = {
  getActivity:  {
    __typename: "Activity",
    id: string,
    itineraryID: string,
    name: string,
    type: ActivityType | null,
    cost: number | null,
    startTime: string | null,
    endTime: string | null,
    notes: Array< string | null > | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListActivitysQueryVariables = {
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListActivitysQuery = {
  listActivitys:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      id: string,
      itineraryID: string,
      name: string,
      type: ActivityType | null,
      cost: number | null,
      startTime: string | null,
      endTime: string | null,
      notes: Array< string | null > | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetPackingListQueryVariables = {
  id: string,
};

export type GetPackingListQuery = {
  getPackingList:  {
    __typename: "PackingList",
    id: string,
    items:  {
      __typename: "ModelPackingItemConnection",
      items:  Array< {
        __typename: "PackingItem",
        id: string,
        packingListID: string,
        name: string | null,
        completed: boolean | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListPackingListsQueryVariables = {
  filter?: ModelPackingListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPackingListsQuery = {
  listPackingLists:  {
    __typename: "ModelPackingListConnection",
    items:  Array< {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetPackingItemQueryVariables = {
  id: string,
};

export type GetPackingItemQuery = {
  getPackingItem:  {
    __typename: "PackingItem",
    id: string,
    packingListID: string,
    name: string | null,
    completed: boolean | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListPackingItemsQueryVariables = {
  filter?: ModelPackingItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPackingItemsQuery = {
  listPackingItems:  {
    __typename: "ModelPackingItemConnection",
    items:  Array< {
      __typename: "PackingItem",
      id: string,
      packingListID: string,
      name: string | null,
      completed: boolean | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateTripSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnCreateTripSubscription = {
  onCreateTrip:  {
    __typename: "Trip",
    id: string,
    title: string,
    itinerary:  {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    packingList:  {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    startDate: string | null,
    endDate: string | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdateTripSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnUpdateTripSubscription = {
  onUpdateTrip:  {
    __typename: "Trip",
    id: string,
    title: string,
    itinerary:  {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    packingList:  {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    startDate: string | null,
    endDate: string | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeleteTripSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnDeleteTripSubscription = {
  onDeleteTrip:  {
    __typename: "Trip",
    id: string,
    title: string,
    itinerary:  {
      __typename: "Itinerary",
      id: string,
      activities:  {
        __typename: "ModelActivityConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    packingList:  {
      __typename: "PackingList",
      id: string,
      items:  {
        __typename: "ModelPackingItemConnection",
        nextToken: string | null,
      } | null,
      invitees: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    startDate: string | null,
    endDate: string | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreateItinerarySubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnCreateItinerarySubscription = {
  onCreateItinerary:  {
    __typename: "Itinerary",
    id: string,
    activities:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        itineraryID: string,
        name: string,
        type: ActivityType | null,
        cost: number | null,
        startTime: string | null,
        endTime: string | null,
        notes: Array< string | null > | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdateItinerarySubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnUpdateItinerarySubscription = {
  onUpdateItinerary:  {
    __typename: "Itinerary",
    id: string,
    activities:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        itineraryID: string,
        name: string,
        type: ActivityType | null,
        cost: number | null,
        startTime: string | null,
        endTime: string | null,
        notes: Array< string | null > | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeleteItinerarySubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnDeleteItinerarySubscription = {
  onDeleteItinerary:  {
    __typename: "Itinerary",
    id: string,
    activities:  {
      __typename: "ModelActivityConnection",
      items:  Array< {
        __typename: "Activity",
        id: string,
        itineraryID: string,
        name: string,
        type: ActivityType | null,
        cost: number | null,
        startTime: string | null,
        endTime: string | null,
        notes: Array< string | null > | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreateActivitySubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnCreateActivitySubscription = {
  onCreateActivity:  {
    __typename: "Activity",
    id: string,
    itineraryID: string,
    name: string,
    type: ActivityType | null,
    cost: number | null,
    startTime: string | null,
    endTime: string | null,
    notes: Array< string | null > | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdateActivitySubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnUpdateActivitySubscription = {
  onUpdateActivity:  {
    __typename: "Activity",
    id: string,
    itineraryID: string,
    name: string,
    type: ActivityType | null,
    cost: number | null,
    startTime: string | null,
    endTime: string | null,
    notes: Array< string | null > | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeleteActivitySubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnDeleteActivitySubscription = {
  onDeleteActivity:  {
    __typename: "Activity",
    id: string,
    itineraryID: string,
    name: string,
    type: ActivityType | null,
    cost: number | null,
    startTime: string | null,
    endTime: string | null,
    notes: Array< string | null > | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreatePackingListSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnCreatePackingListSubscription = {
  onCreatePackingList:  {
    __typename: "PackingList",
    id: string,
    items:  {
      __typename: "ModelPackingItemConnection",
      items:  Array< {
        __typename: "PackingItem",
        id: string,
        packingListID: string,
        name: string | null,
        completed: boolean | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdatePackingListSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnUpdatePackingListSubscription = {
  onUpdatePackingList:  {
    __typename: "PackingList",
    id: string,
    items:  {
      __typename: "ModelPackingItemConnection",
      items:  Array< {
        __typename: "PackingItem",
        id: string,
        packingListID: string,
        name: string | null,
        completed: boolean | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeletePackingListSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnDeletePackingListSubscription = {
  onDeletePackingList:  {
    __typename: "PackingList",
    id: string,
    items:  {
      __typename: "ModelPackingItemConnection",
      items:  Array< {
        __typename: "PackingItem",
        id: string,
        packingListID: string,
        name: string | null,
        completed: boolean | null,
        invitees: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreatePackingItemSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnCreatePackingItemSubscription = {
  onCreatePackingItem:  {
    __typename: "PackingItem",
    id: string,
    packingListID: string,
    name: string | null,
    completed: boolean | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdatePackingItemSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnUpdatePackingItemSubscription = {
  onUpdatePackingItem:  {
    __typename: "PackingItem",
    id: string,
    packingListID: string,
    name: string | null,
    completed: boolean | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeletePackingItemSubscriptionVariables = {
  owner: string,
  invitees: string,
};

export type OnDeletePackingItemSubscription = {
  onDeletePackingItem:  {
    __typename: "PackingItem",
    id: string,
    packingListID: string,
    name: string | null,
    completed: boolean | null,
    invitees: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://2vw4bdl4qrguzojb2mv5au76se.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:45b8581f-4b51-4406-888d-145ddc62f942",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_65q2o9Jr4",
    "aws_user_pools_web_client_id": "cjvcnhcio4g38b0ujjrshk3on",
    "oauth": {},
    "aws_user_files_s3_bucket": "goingapp46a76b0a5a7342eab40609a0a66fe3fa173100-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;

import { API, Auth, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

interface GraphQLOptions {
  input?: object;
  variables?: object;
  authMode?: GRAPHQL_AUTH_MODE;
}

async function callGraphQL<T>(
  query: any,
  options?: GraphQLOptions | object
): Promise<GraphQLResult<T>> {
  const session = await Auth.currentSession();
  return (await API.graphql(
    graphqlOperation(query, options),
    {
      Authorization: session.getIdToken().getJwtToken()
    }
  )) as GraphQLResult<T>;
}

export default callGraphQL;

import { useState } from "react";
import "../styles/_tabs.scss";

type TabsProps = {
  tabs: Tab[];
};

export type Tab = {
  title: string;
  element: React.ReactNode;
};

const Tabs = ({ tabs }: TabsProps) => {
  const [active, setActive] = useState<number>(0);

  const changeTab = (index: number) => {
    setActive(index);
  };

  return (
    <>
      <ul className="tab-item-list">
        {tabs.map((tab, index) => {
          return (
            <li
              className={`tab-title ${index === active ? "active" : ""}`}
              key={tab.title}
              onClick={(e) => changeTab(index)}
            >
              {tab.title}
            </li>
          );
        })}
      </ul>
      <div className="tab-item-container">
        {tabs.map((tab, index) => (
          <div
            className={`tab-item ${index === active ? "active" : ""}`}
            style={{ left: `${(index - active) * 100}%` }}
            key={tab.title}
          >
            {tab.element}
          </div>
        ))}
      </div>
    </>
  );
};

export default Tabs;

import "../styles/_forms.scss";
import { ChangeEvent, FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { create as createTrip, TripModel } from "../models/Trip";

const CreateTrip = () => {
  const history = useHistory();
  const [trip, setTrip] = useState<TripModel>({ title: "" });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTrip((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await createTrip(trip);
    history.push(`/trip/${response?.id}`);
  };

  return (
    <>
      <h1 className="px-1">Create Trip</h1>
      <form className="px-1" onSubmit={submit}>
        <div className="form-group">
          <label htmlFor="title">Trip Title</label>
          <input
            value={trip.title || ""}
            onChange={handleChange}
            type="text"
            name="title"
            id="title"
          />
        </div>
        <div className="form-group">
          <label htmlFor="startDate">Start Date</label>
          <input
            value={trip.startDate || ""}
            onChange={handleChange}
            type="date"
            name="startDate"
            id="startDate"
          />
        </div>
        <div className="form-group">
          <label htmlFor="endDate">End Date</label>
          <input
            value={trip.endDate || ""}
            onChange={handleChange}
            type="date"
            name="endDate"
            id="endDate"
          />
        </div>
        <div className="form-group">
          <button type="submit">Submit</button>
        </div>
      </form>
    </>
  );
};

export default CreateTrip;

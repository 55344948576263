import { ChangeEvent, useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { useParams } from "react-router-dom";
import { RouterParams } from "../models/App";
import { get as getTrip, TripModel } from "../models/Trip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

import Tabs from "../components/Tabs";
import ActivitiesView from "./ActivitiesView";
import PackingListView from "./PackingListView";
import ShareView from "./ShareView";
import Loader from "../components/Loader";

const TripView = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [trip, setTrip] = useState<TripModel>();
  const [image, setImage] = useState<string>();
  const { id } = useParams<RouterParams>();

  const tabs = [
    {
      title: "Activites",
      element: (
        <ActivitiesView
          trip={trip!}
          itinerary={trip?.itinerary!}
          fetchTrip={fetchTrip}
        />
      ),
    },
    {
      title: "Packing List",
      element: (
        <PackingListView
          packingList={trip?.packingList!}
          fetchTrip={fetchTrip}
        />
      ),
    },
    {
      title: "Share",
      element: <ShareView trip={trip!} fetchTrip={fetchTrip} />,
    },
  ];

  useEffect(() => {
    initTrip();
    // eslint-disable-next-line
  }, []);

  async function fetchTrip() {
    try {
      const trip = await getTrip(id!);
      setTrip(trip);
    } catch (err) {
      console.log("error fetching trip", err);
    }
  }

  async function initTrip() {
    try {
      const trip = await getTrip(id!);
      setTrip(trip);
      const img = await Storage.vault.get(trip?.id!);
      const response = await fetch(img.toString());
      if (response.status !== 404) {
        setImage(img.toString());
      }
      setLoaded(true);
    } catch (err) {
      console.log("error fetching trip", err);
    }
  }

  async function onChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files![0];
    try {
      await Storage.vault.put(trip?.id!, file);
      const img = await Storage.vault.get(trip?.id!);
      setImage(img.toString());
    } catch (err) {
      console.log("Error uploading file: ", err);
    }
  }

  const setBackgroundImage = () => {
    if (image) {
      return { backgroundImage: `url('${image}')` };
    } else {
      return {
        backgroundColor: "var(--dark)",
        height: "4rem",
      };
    }
  };

  return loaded ? (
    <>
      <div className="trip-image bg-image" style={setBackgroundImage()}>
        <label htmlFor="tripImage">
          <FontAwesomeIcon icon={faCamera} size="1x" />
        </label>
        <input
          id="tripImage"
          name="tripImage"
          type="file"
          onChange={onChange}
          style={{ display: "none" }}
        />
      </div>
      <h2 className="p-05 mb-05">{trip?.title}</h2>
      <Tabs tabs={tabs} />
    </>
  ) : (
    <Loader />
  );
};

export default TripView;

import {
  CreateTripInput,
  CreateTripMutation,
  DeleteActivityMutation,
  DeleteItineraryMutation,
  DeletePackingItemMutation,
  DeletePackingListMutation,
  DeleteTripMutation,
  GetTripQuery,
  ListTripsQuery,
  UpdateTripInput,
  UpdateTripMutation,
} from "../API";
import { GraphQLResult } from "@aws-amplify/api";

import callGraphQL from "../services/GraphQLService";
import {
  createTrip,
  deleteActivity,
  deleteItinerary,
  deletePackingItem,
  deletePackingList,
  deleteTrip,
  updateTrip,
} from "../graphql/mutations";
import { create as createItinerary, ItineraryModel } from "../models/Itinerary";
import {
  create as createPackingList,
  PackingListModel,
} from "../models/PackingList";
import { listTrips, getTrip } from "../graphql/queries";
import Auth from "@aws-amplify/auth";

export interface TripModel {
  id?: string;
  title: string;
  itinerary?: ItineraryModel;
  packingList?: PackingListModel;
  startDate?: string;
  endDate?: string;
  createdAt?: string;
  updatedAt?: string;
  owner?: string;
  invitees?: string[];
}

export const mapListTrips = (query: GraphQLResult<ListTripsQuery>) => {
  return query.data?.listTrips?.items?.map(
    (trip) =>
      (({
        id: trip?.id,
        title: trip?.title,
        startDate: trip?.startDate,
        endDate: trip?.endDate,
        owner: trip?.owner,
      } as TripModel) || [])
  );
};

export const create = async (data: CreateTripInput) => {
  const user = await Auth.currentSession();
  const email = user.getIdToken().payload.email;
  const itinerary = await createItinerary({ invitees: [email] });
  const packingList = await createPackingList({ invitees: [email] });

  const tripResponse = await callGraphQL<CreateTripMutation>(createTrip, {
    input: {
      ...data,
      tripItineraryId: itinerary?.id,
      tripPackingListId: packingList?.id,
      invitees: [email],
    },
  });

  return tripResponse.data?.createTrip;
};

export const list = async () => {
  const tripsData = await callGraphQL<ListTripsQuery>(listTrips);
  console.log(tripsData);
  return mapListTrips(tripsData);
};

export const get = async (id: string) => {
  const tripData = await callGraphQL<GetTripQuery>(getTrip, { id });
  console.log(tripData);
  return tripData.data?.getTrip as TripModel;
};

export const update = async ({
  id,
  title,
  startDate,
  endDate,
  invitees,
}: UpdateTripInput) => {
  await callGraphQL<UpdateTripMutation>(updateTrip, {
    input: {
      id,
      title,
      startDate,
      endDate,
      invitees,
    },
  });
};

export const del = async (id: string) => {
  const tripData = await callGraphQL<DeleteTripMutation>(deleteTrip, {
    input: { id },
  });

  const itineraryId = tripData.data?.deleteTrip?.itinerary?.id;
  const packingListId = tripData.data?.deleteTrip?.packingList?.id;

  // Delete Itinerary
  const itineraryData = await callGraphQL<DeleteItineraryMutation>(
    deleteItinerary,
    {
      input: { id: itineraryId },
    }
  );

  // Delete Activities
  const activities = itineraryData.data?.deleteItinerary?.activities?.items;

  const promActivites = [];
  for (const activity of activities!) {
    promActivites.push(
      callGraphQL<DeleteActivityMutation>(deleteActivity, {
        input: { id: activity?.id },
      })
    );
  }
  await Promise.all(promActivites);

  // Delete packing list
  const packingListData = await callGraphQL<DeletePackingListMutation>(
    deletePackingList,
    {
      input: { id: packingListId },
    }
  );

  // Delete packing items
  const listItems = packingListData.data?.deletePackingList?.items?.items;

  const promListItems = [];
  for (const item of listItems!) {
    promListItems.push(
      callGraphQL<DeletePackingItemMutation>(deletePackingItem, {
        input: { id: item?.id },
      })
    );
  }
  await Promise.all(promListItems);
};

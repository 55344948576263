/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTrip = /* GraphQL */ `
  query GetTrip($id: ID!) {
    getTrip(id: $id) {
      id
      title
      itinerary {
        id
        activities {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      packingList {
        id
        items {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTrips = /* GraphQL */ `
  query ListTrips(
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        itinerary {
          id
          invitees
          createdAt
          updatedAt
          owner
        }
        packingList {
          id
          invitees
          createdAt
          updatedAt
          owner
        }
        startDate
        endDate
        invitees
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getItinerary = /* GraphQL */ `
  query GetItinerary($id: ID!) {
    getItinerary(id: $id) {
      id
      activities {
        items {
          id
          itineraryID
          name
          type
          cost
          startTime
          endTime
          notes
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listItinerarys = /* GraphQL */ `
  query ListItinerarys(
    $filter: ModelItineraryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItinerarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activities {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      itineraryID
      name
      type
      cost
      startTime
      endTime
      notes
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itineraryID
        name
        type
        cost
        startTime
        endTime
        notes
        invitees
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPackingList = /* GraphQL */ `
  query GetPackingList($id: ID!) {
    getPackingList(id: $id) {
      id
      items {
        items {
          id
          packingListID
          name
          completed
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPackingLists = /* GraphQL */ `
  query ListPackingLists(
    $filter: ModelPackingListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackingLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        items {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPackingItem = /* GraphQL */ `
  query GetPackingItem($id: ID!) {
    getPackingItem(id: $id) {
      id
      packingListID
      name
      completed
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPackingItems = /* GraphQL */ `
  query ListPackingItems(
    $filter: ModelPackingItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackingItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        packingListID
        name
        completed
        invitees
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

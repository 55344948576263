import {
  CreateItineraryInput,
  CreateItineraryMutation,
  GetItineraryQuery,
} from "../API";
import { createItinerary } from "../graphql/mutations";
import { getItinerary } from "../graphql/queries";
import callGraphQL from "../services/GraphQLService";
import { ActivityModel } from "./Activity";

export interface ItineraryModel {
  id: string;
  activities?: {
    items?: ActivityModel[];
  };
  invitees?: string[];
}

export const create = async (data: CreateItineraryInput) => {
  const response = await callGraphQL<CreateItineraryMutation>(createItinerary, {
    input: { ...data },
  });

  return response.data?.createItinerary;
};

export const get = async (id: string) => {
  const tripData = await callGraphQL<GetItineraryQuery>(getItinerary, { id });
  const itinerary = tripData.data?.getItinerary as ItineraryModel;
  itinerary.activities?.items?.sort(
    (a, b) =>
      new Date(a.startTime!).getTime() - new Date(b.startTime!).getTime()
  );
  return itinerary;
};

import { TripModel } from "./Trip";
import { get as getItinerary } from "./Itinerary";
import { get as getPackingList } from "./PackingList";
import callGraphQL from "../services/GraphQLService";
import {
  UpdateActivityMutation,
  UpdateItineraryMutation,
  UpdatePackingItemMutation,
  UpdatePackingListInput,
  UpdateTripMutation,
} from "../API";
import {
  updateActivity,
  updateItinerary,
  updatePackingItem,
  updatePackingList,
  updateTrip,
} from "../graphql/mutations";

export const addInvitee = async (trip: TripModel, invitee: string) => {
  const invitees = trip.invitees ? [...trip.invitees!, invitee] : [invitee];
  update(trip, invitees);

  console.log(trip, invitee);
};

export const removeInvitee = async (trip: TripModel, invitee: string) => {
  if (window.confirm("Do you want to remove this invitee?")) {
    const invitees = trip.invitees?.filter((i) => i !== invitee);
    update(trip, invitees!);
  }
};

const update = async (trip: TripModel, invitees: string[]) => {
  const itinerary = await getItinerary(trip.itinerary?.id!);
  const packingList = await getPackingList(trip.packingList?.id!);

  await Promise.all([
    // Update Trip
    callGraphQL<UpdateTripMutation>(updateTrip, {
      input: {
        id: trip.id,
        invitees,
      },
    }),
    // Update Activities
    callGraphQL<UpdateItineraryMutation>(updateItinerary, {
      input: {
        id: trip.itinerary?.id,
        invitees,
      },
    }),
    Array.from(itinerary.activities?.items!, ({ id }) =>
      callGraphQL<UpdateActivityMutation>(updateActivity, {
        input: { id, invitees },
      })
    ),
    // Update Packing List
    callGraphQL<UpdatePackingListInput>(updatePackingList, {
      input: {
        id: trip.packingList?.id,
        invitees,
      },
    }),
    Array.from(packingList.items.items!, ({ id }) =>
      callGraphQL<UpdatePackingItemMutation>(updatePackingItem, {
        input: { id, invitees },
      })
    ),
  ]);
};

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTrip = /* GraphQL */ `
  mutation CreateTrip(
    $input: CreateTripInput!
    $condition: ModelTripConditionInput
  ) {
    createTrip(input: $input, condition: $condition) {
      id
      title
      itinerary {
        id
        activities {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      packingList {
        id
        items {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTrip = /* GraphQL */ `
  mutation UpdateTrip(
    $input: UpdateTripInput!
    $condition: ModelTripConditionInput
  ) {
    updateTrip(input: $input, condition: $condition) {
      id
      title
      itinerary {
        id
        activities {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      packingList {
        id
        items {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTrip = /* GraphQL */ `
  mutation DeleteTrip(
    $input: DeleteTripInput!
    $condition: ModelTripConditionInput
  ) {
    deleteTrip(input: $input, condition: $condition) {
      id
      title
      itinerary {
        id
        activities {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      packingList {
        id
        items {
          nextToken
        }
        invitees
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createItinerary = /* GraphQL */ `
  mutation CreateItinerary(
    $input: CreateItineraryInput!
    $condition: ModelItineraryConditionInput
  ) {
    createItinerary(input: $input, condition: $condition) {
      id
      activities {
        items {
          id
          itineraryID
          name
          type
          cost
          startTime
          endTime
          notes
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateItinerary = /* GraphQL */ `
  mutation UpdateItinerary(
    $input: UpdateItineraryInput!
    $condition: ModelItineraryConditionInput
  ) {
    updateItinerary(input: $input, condition: $condition) {
      id
      activities {
        items {
          id
          itineraryID
          name
          type
          cost
          startTime
          endTime
          notes
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteItinerary = /* GraphQL */ `
  mutation DeleteItinerary(
    $input: DeleteItineraryInput!
    $condition: ModelItineraryConditionInput
  ) {
    deleteItinerary(input: $input, condition: $condition) {
      id
      activities {
        items {
          id
          itineraryID
          name
          type
          cost
          startTime
          endTime
          notes
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      itineraryID
      name
      type
      cost
      startTime
      endTime
      notes
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      itineraryID
      name
      type
      cost
      startTime
      endTime
      notes
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      itineraryID
      name
      type
      cost
      startTime
      endTime
      notes
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPackingList = /* GraphQL */ `
  mutation CreatePackingList(
    $input: CreatePackingListInput!
    $condition: ModelPackingListConditionInput
  ) {
    createPackingList(input: $input, condition: $condition) {
      id
      items {
        items {
          id
          packingListID
          name
          completed
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePackingList = /* GraphQL */ `
  mutation UpdatePackingList(
    $input: UpdatePackingListInput!
    $condition: ModelPackingListConditionInput
  ) {
    updatePackingList(input: $input, condition: $condition) {
      id
      items {
        items {
          id
          packingListID
          name
          completed
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePackingList = /* GraphQL */ `
  mutation DeletePackingList(
    $input: DeletePackingListInput!
    $condition: ModelPackingListConditionInput
  ) {
    deletePackingList(input: $input, condition: $condition) {
      id
      items {
        items {
          id
          packingListID
          name
          completed
          invitees
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPackingItem = /* GraphQL */ `
  mutation CreatePackingItem(
    $input: CreatePackingItemInput!
    $condition: ModelPackingItemConditionInput
  ) {
    createPackingItem(input: $input, condition: $condition) {
      id
      packingListID
      name
      completed
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePackingItem = /* GraphQL */ `
  mutation UpdatePackingItem(
    $input: UpdatePackingItemInput!
    $condition: ModelPackingItemConditionInput
  ) {
    updatePackingItem(input: $input, condition: $condition) {
      id
      packingListID
      name
      completed
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePackingItem = /* GraphQL */ `
  mutation DeletePackingItem(
    $input: DeletePackingItemInput!
    $condition: ModelPackingItemConditionInput
  ) {
    deletePackingItem(input: $input, condition: $condition) {
      id
      packingListID
      name
      completed
      invitees
      createdAt
      updatedAt
      owner
    }
  }
`;

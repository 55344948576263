import "../styles/_nav.scss";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPlusCircle, faUser } from '@fortawesome/free-solid-svg-icons'

const Nav = () => {
  return (
    <ul className="nav">
      <li className="nav-item">
        <NavLink exact to="/"><FontAwesomeIcon icon={faHome} size="2x" /></NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/create"><FontAwesomeIcon icon={faPlusCircle} size="2x" /></NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/profile"><FontAwesomeIcon icon={faUser} size="2x" /></NavLink>
      </li>
    </ul>
  );
};

export default Nav;

import { useEffect, useState } from "react";
import { ItineraryModel, get as getItinerary } from "../models/Itinerary";
import { ActivityModel, del as deleteActivity } from "../models/Activity";
import CreateActivity from "./CreateActivity";

import {
  faTimes,
  faUmbrellaBeach,
  faMountain,
  faLocationArrow,
  faSnowflake,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/_activities.scss";
import { TripModel } from "../models/Trip";

type ActivitiesViewProps = {
  trip: TripModel;
  itinerary: ItineraryModel;
  fetchTrip: Function;
};

type DayId = {
  id: string;
  month: number;
  day: number;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ActivitiesView = (props: ActivitiesViewProps) => {
  const [activities, setActivities] = useState<ActivityModel[]>();
  const [dayIds, setDayIds] = useState<DayId[]>([]);
  const [editActivity, setEditActivity] = useState<ActivityModel>();
  const [showAddActivity, setShowAddActivity] = useState<boolean>(false);

  useEffect(() => {
    if (props.itinerary?.id) fetchActivities();
  }, [props.itinerary]);

  const fetchActivities = async () => {
    try {
      const response = await getItinerary(props.itinerary?.id!);
      const activities = response.activities?.items;

      let days: DayId[] = [];
      for (const activity of activities!) {
        if (!activity.startTime) continue;
        const month = new Date(activity.startTime!).getMonth();
        const day = new Date(activity.startTime!).getDate();
        if (days.findIndex((d) => d.day === day) === -1) {
          days = [...days, { id: activity.id!, month, day }];
        }
      }
      setDayIds(days);
      setActivities(activities);
    } catch (err) {
      console.log("error fetching activities", err);
    }
  };

  const createActivity = () => {
    setEditActivity(undefined);
    setShowAddActivity(true);
  };

  const edit = (activity: ActivityModel) => {
    setEditActivity(activity);
    setShowAddActivity(true);
  };

  const removeActivity = (activity: ActivityModel) => {
    if (window.confirm("Do you want to delete this activity?")) {
      deleteActivity(activity.id!);
      fetchActivities();
    }
  };

  const formatTime = (activity: ActivityModel) => {
    const start = activity.startTime
      ? new Date(activity.startTime!).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        })
      : "Anytime";
    const end = activity.endTime
      ? new Date(activity.endTime!).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        })
      : null;

    return end ? `${start} - ${end}` : `${start}`;
  };

  const typeIcon = (type: string) => {
    switch (type) {
      case "beach":
        return <FontAwesomeIcon icon={faUmbrellaBeach} size="1x" />;
      case "mountain":
        return <FontAwesomeIcon icon={faMountain} size="1x" />;
      case "desert":
        return <FontAwesomeIcon icon={faSun} size="1x" />;
      case "snow":
        return <FontAwesomeIcon icon={faSnowflake} size="1x" />;
      default:
        return <FontAwesomeIcon icon={faLocationArrow} size="1x" />;
    }
  };

  const dayItem = (day: DayId | undefined) => {
    if (day) {
      return (
        <li className="day" key={`${day.month}-${day.day}`}>
          <span>
            {monthNames[day.month - 1] + " "}
            {day.day}
          </span>
        </li>
      );
    }
  };

  const activityItem = (activity: ActivityModel) => {
    const day = dayIds.find((d) => d.id === activity.id);
    return (
      <>
        {dayItem(day)}
        <li
          className="activity"
          key={activity.id}
          onClick={() => {
            edit(activity);
          }}
        >
          <div className="type">{typeIcon(activity.type!)}</div>
          <div className="info">
            <div className="name">{activity.name}</div>
            <div className="time">{formatTime(activity)}</div>
          </div>
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation();
              removeActivity(activity);
            }}
            icon={faTimes}
            size="1x"
          />
        </li>
      </>
    );
  };

  return (
    <>
      <ul className="list">
        {activities
          ? activities.map((activity) => {
              return activityItem(activity);
            })
          : ""}

        <li className="add-activity" onClick={createActivity}>
          <button>Add Activity</button>
        </li>
      </ul>
      <CreateActivity
        itinerary={props.itinerary}
        minDate={props.trip?.startDate!}
        maxDate={props.trip?.endDate!}
        activity={editActivity!}
        fetchTrip={() => {
          setShowAddActivity(false);
          props.fetchTrip();
        }}
        closeView={() => {
          setShowAddActivity(false);
        }}
        active={showAddActivity}
      />
    </>
  );
};

export default ActivitiesView;

import { Switch, Route } from "react-router-dom";

// Views
import TripView from "../views/TripView";
import Trips from "../views/Trips";
import CreateTrip from "../views/CreateTrip";
import Profile from "../views/Profile";

const RouterSwitch = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Trips />
      </Route>
      <Route exact path="/create">
        <CreateTrip />
      </Route>
      <Route exact path="/profile">
        <Profile />
      </Route>
      <Route path="/trip/:id">
        <TripView />
      </Route>
    </Switch>
  );
};

export default RouterSwitch;
import { TripModel, del as deleteTrip } from "../models/Trip";
import { useHistory } from "react-router-dom";
import { ChangeEvent, FormEvent, useState } from "react";
import { addInvitee, removeInvitee } from "../models/Invitee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type ShareViewProps = {
  trip: TripModel;
  fetchTrip: Function;
};

const ShareView = (props: ShareViewProps) => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const removeTrip = () => {
    if (window.confirm("Do you want to delete this trip?")) {
      deleteTrip(props.trip.id!);
      history.push("/");
      props.fetchTrip();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await addInvitee(props.trip, email);
    props.fetchTrip();
  };

  return (
    <>
      <ul className="list">
        {props.trip && props.trip.invitees
          ? props.trip.invitees.map((invitee) => {
              return (
                <li className="item" key={invitee}>
                  <span style={{ flexGrow: 1, padding: "0.5rem 0.25rem" }}>
                    {invitee}
                  </span>
                  <FontAwesomeIcon
                    onClick={() => {
                      removeInvitee(props.trip, invitee);
                      props.fetchTrip();
                    }}
                    icon={faTimes}
                    size="1x"
                  />
                </li>
              );
            })
          : ""}
      </ul>

      <form onSubmit={submit}>
        <div className="form-group">
          <label htmlFor="email">Invite More!</label>
          <input
            value={email || ""}
            onChange={handleChange}
            type="email"
            name="email"
            id="email"
          />
        </div>
        <div className="form-group">
          <button type="submit">Submit</button>
        </div>
      </form>
      <hr className="my-1" />
      <form onSubmit={submit}>
        <div className="form-group">
          <button onClick={removeTrip}>Delete Trip</button>
        </div>
      </form>
    </>
  );
};

export default ShareView;

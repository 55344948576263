import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ItineraryModel } from "../models/Itinerary";
import {
  ActivityModel,
  create as createActivity,
  update as updateActivity,
} from "../models/Activity";
import "../styles/_forms.scss";
import "../styles/_create-activity.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ActivityType } from "../API";

type CreateActivityProps = {
  itinerary: ItineraryModel;
  activity: ActivityModel;
  fetchTrip: Function;
  closeView: Function;
  minDate: string;
  maxDate: string;
  active: boolean;
};

const CreateActivity = (props: CreateActivityProps) => {
  const [activity, setActivity] = useState<ActivityModel>({ name: "" });

  useEffect(() => {
    if (props.activity) {
      setActivity(props.activity);
    } else {
      setActivity({ name: "" });
    }
  }, [props.activity]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setActivity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(activity);
    if (props.activity) {
      updateActivity({
        ...activity,
        id: props.activity?.id!,
        invitees: props.itinerary.invitees,
      });
    } else {
      createActivity({
        ...activity,
        itineraryID: props.itinerary?.id!,
        invitees: props.itinerary.invitees,
      });
    }
    props.fetchTrip();
    setActivity({ name: "" });
  };

  const formatDateValue = (date: string): string => {
    const dateSplit = date.split(":");
    return `${dateSplit[0]}:${dateSplit[1]}`;
  };

  const formatDate = (date: string): string => {
    return new Date(date || 0).toISOString().split("T")[0] + "T23:59";
  };

  return (
    <div className={`overlay ${props.active ? "active" : ""}`}>
      <div className="close-overlay" onClick={() => props.closeView()}></div>
      <div className="close" onClick={() => props.closeView()}>
        <FontAwesomeIcon icon={faChevronDown} size="1x" />
      </div>
      <form onSubmit={submit}>
        <div className="form-group">
          <label htmlFor="name">Activity Name</label>
          <input
            value={activity.name || ""}
            onChange={handleChange}
            type="text"
            name="name"
            id="name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Activity Type</label>
          <select
            name="type"
            id="type"
            value={activity.type || ""}
            onChange={handleChange}
          >
            <option value="">None</option>
            <option value={ActivityType.beach}>Beach</option>
            <option value={ActivityType.desert}>Desert</option>
            <option value={ActivityType.mountain}>Mountain</option>
            <option value={ActivityType.snow}>Snow</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">Start Time</label>
          <input
            type="datetime-local"
            id="startTime"
            name="startTime"
            value={formatDateValue(activity.startTime || "")}
            onChange={handleChange}
            min={formatDate(props.minDate)}
            max={formatDate(props.maxDate)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">End Time</label>
          <input
            type="datetime-local"
            id="endTime"
            name="endTime"
            value={formatDateValue(activity.endTime || "")}
            onChange={handleChange}
            min={formatDate(props.minDate)}
            max={formatDate(props.maxDate)}
          />
        </div>
        <div className="form-group">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default CreateActivity;

import {
  CreatePackingItemInput,
  CreatePackingItemMutation,
  CreatePackingListInput,
  CreatePackingListMutation,
  DeletePackingItemInput,
  DeletePackingItemMutation,
  GetPackingListQuery,
  UpdatePackingItemMutation,
} from "../API";
import {
  createPackingItem,
  createPackingList,
  deletePackingItem,
  updatePackingItem,
} from "../graphql/mutations";
import { getPackingList } from "../graphql/queries";
import callGraphQL from "../services/GraphQLService";

export type PackingListModel = {
  id: string;
  items: {
    items?: PackingItemModel[];
  };
  invitees?: string[];
};

export type PackingItemModel = {
  id: string;
  name: string;
  completed: boolean;
  invitees?: string[];
};

export const create = async (data: CreatePackingListInput) => {
  const response = await callGraphQL<CreatePackingListMutation>(
    createPackingList,
    {
      input: {
        ...data
      },
    }
  );

  return response.data?.createPackingList;
};

export const get = async (id: string) => {
  const tripData = await callGraphQL<GetPackingListQuery>(getPackingList, {
    id,
  });
  return tripData.data?.getPackingList as PackingListModel;
};

export const add = async (data: CreatePackingItemInput) => {
  const response = await callGraphQL<CreatePackingItemMutation>(
    createPackingItem,
    {
      input: { ...data },
    }
  );

  return response.data?.createPackingItem;
};

export const remove = async ({ id }: DeletePackingItemInput) => {
  await callGraphQL<DeletePackingItemMutation>(deletePackingItem, {
    input: { id },
  });
};

export const save = async ({ id, name, completed }: PackingItemModel) => {
  await callGraphQL<UpdatePackingItemMutation>(updatePackingItem, {
    input: { id, name, completed },
  });
};

import {
  ActivityType,
  CreateActivityInput,
  CreateActivityMutation,
  DeleteActivityMutation,
  UpdateActivityInput,
  UpdateActivityMutation,
} from "../API";
import {
  createActivity,
  deleteActivity,
  updateActivity,
} from "../graphql/mutations";
import callGraphQL from "../services/GraphQLService";

export type ActivityModel = {
  id?: string;
  itineraryID?: string;
  name: string;
  description?: string;
  type?: ActivityType;
  cost?: number;
  startTime?: string;
  endTime?: string;
  notes?: string[];
  invitees?: string[];
};

export const create = async (data: CreateActivityInput) => {
  const start = data.startTime ? `${data.startTime}:00.000Z` : data.startTime;
  const end = data.endTime ? `${data.endTime}:00.000Z` : data.endTime;

  const response = await callGraphQL<CreateActivityMutation>(createActivity, {
    input: { ...data, startTime: start, endTime: end },
  });

  return response.data?.createActivity;
};

export const update = async ({
  id,
  itineraryID,
  name,
  type,
  cost,
  startTime,
  endTime,
  notes,
  invitees,
}: UpdateActivityInput) => {
  let start = startTime;
  let end = endTime;

  if (!startTime?.includes("Z")) {
    start = `${startTime}:00.000Z`;
  }

  if (endTime && !endTime?.includes("Z")) {
    end = `${endTime}:00.000Z`;
  }

  const response = await callGraphQL<UpdateActivityMutation>(updateActivity, {
    input: {
      id,
      itineraryID,
      name,
      type,
      cost,
      startTime: start,
      endTime: end,
      notes,
      invitees,
    },
  });
};

export const del = async (id: string) => {
  await callGraphQL<DeleteActivityMutation>(deleteActivity, {
    input: { id },
  });
};

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  PackingListModel,
  get as getPackingList,
  add as addPackingItem,
  save as savePackingItem,
  remove as removePackingItem,
  PackingItemModel,
} from "../models/PackingList";
import "../styles/_packing-list.scss";

type PackingListViewProps = {
  packingList: PackingListModel;
  fetchTrip: Function;
};

const PackingListView = (props: PackingListViewProps) => {
  const [areChanges, setAreChanges] = useState(false);
  const [packingItems, setPackingItems] = useState<PackingItemModel[]>();

  useEffect(() => {
    if (props.packingList?.id) fetchItems();
  }, [props.packingList]);

  const fetchItems = async () => {
    try {
      const response = await getPackingList(props.packingList?.id!);
      setPackingItems(response.items?.items);
    } catch (err) {
      console.log("error fetching packing items", err);
    }
  };

  const addItem = () => {
    addPackingItem({
      name: "",
      packingListID: props.packingList?.id!,
      invitees: props.packingList.invitees,
    });
    props.fetchTrip();
  };

  const removeItem = (item: PackingItemModel) => {
    removePackingItem(item);
    props.fetchTrip();
  };

  const saveItem = (item: PackingItemModel) => {
    if (areChanges) {
      savePackingItem(item);
      setAreChanges(false);
    }
  };

  const updateItem = (item: PackingItemModel) => {
    const updated = packingItems?.map((i) => (i.id === item.id ? item : i));
    setPackingItems(updated);
    setAreChanges(true);
  };

  const editItem = (name: string, item: PackingItemModel) => {
    updateItem({ ...item, name });
  };

  const checkItem = (item: PackingItemModel) => {
    item.completed = item.completed ? !item.completed : true;
    savePackingItem(item);
    updateItem(item);
  };

  return (
    <>
      <ul className="list">
        {packingItems
          ? packingItems.map((item) => {
              return (
                <li className="item" key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    checked={item.completed ? item.completed : false}
                    onChange={() => {
                      checkItem(item);
                    }}
                  />
                  <label htmlFor={item.id}></label>
                  <input
                    type="text"
                    value={item.name}
                    onBlur={() => {
                      saveItem(item);
                    }}
                    onChange={(e) => {
                      editItem(e.target.value, item);
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      removeItem(item);
                    }}
                    icon={faTimes}
                    size="1x"
                  />
                </li>
              );
            })
          : ""}
        <li className="add-item" onClick={addItem}>
          + Packing Item
        </li>
      </ul>
    </>
  );
};

export default PackingListView;

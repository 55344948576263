import { useEffect, useState } from "react";
import { Storage } from "aws-amplify";

import { TripModel, list as listTrips } from "../models/Trip";
import { Link } from "react-router-dom";

import "../styles/_trips.scss";
import Loader from "../components/Loader";

const Trips = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [trips, setTrips] = useState<TripModel[]>([]);
  const [images, setImages] = useState<{ [k: string]: any }>();

  useEffect(() => {
    fetchTrips();
  }, []);

  async function fetchTrips() {
    try {
      const trips = await listTrips();
      setTrips(trips!);
      const imgs: { [k: string]: any } = {};
      for (const trip of trips!) {
        const img = await Storage.vault.get(trip.id!);
        const response = await fetch(img.toString());
        if (response.status !== 404) {
          imgs[trip.id!] = img.toString();
        }
      }
      setImages(imgs);
      setLoaded(true);
    } catch (err) {
      console.log("error fetching trips", err);
    }
  }

  const formatDate = (date?: string) => {
    if (date)
      return new Date(date!).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

    return "No Specified Date";
  };

  return loaded ? (
    <>
      <div className="trips-container">
        {trips?.map((trip) => {
          return (
            <Link key={trip.id} to={`/trip/${trip.id}`}>
              <div className="trip card mb-1">
                <div className="trip-info p-1">
                  <h5 className="m-0">{trip.title}</h5>
                  <p className="m-0">
                    {formatDate(trip.startDate)} - {formatDate(trip.endDate)}
                  </p>
                </div>
                <div
                  className="trip-thumbnail bg-image"
                  style={{
                    backgroundImage: `url('${
                      images ? images[trip?.id!] : ""
                    }')`,
                  }}
                ></div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default Trips;

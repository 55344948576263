import Auth from "@aws-amplify/auth";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";

const Profile = () => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    console.log(user)
    setUser(user);
  };

  return (
    <div className="px-1">
      <h1>Profile</h1>
      <p>Email: {user?.attributes.email}</p>
      <AmplifySignOut />
    </div>
  );
};

export default Profile;
